'use client';

import type { FC } from 'react';
import { useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import AutoHeight from 'embla-carousel-auto-height';
import Autoplay from 'embla-carousel-autoplay';
import type { OptionsType } from 'embla-carousel-autoplay/components/Options';
import type { EmblaOptionsType } from 'embla-carousel-react';
import useEmblaCarousel from 'embla-carousel-react';

import { DotButton } from '../buttons';
import { set } from 'lodash';

type PropTypes = {
  slides: any[];
  parentClassName?: string;
  sliderClassName?: string;
  options: EmblaOptionsType;
  autoPlayOptions?: Partial<OptionsType>;
};

const DotsCarousel: FC<PropTypes> = ({
  autoPlayOptions,
  slides,
  parentClassName,
  sliderClassName,
  options,
}) => {
  const opt = autoPlayOptions ? [Autoplay(autoPlayOptions), AutoHeight()] : [];

  const [viewportRef, embla] = useEmblaCarousel(options, opt);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollTo = useCallback((index: number) => embla && embla.scrollTo(index), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  const onScroll = useCallback(() => {
    if (!embla) return;
  }, [embla]);

  useEffect(() => {
    if (!embla) return;

    const autoplay = embla?.plugins()?.autoplay;

    if (!autoplay) return;

    const playOrStop = autoPlayOptions?.playOnInit ? autoplay.play : autoplay.stop;

    playOrStop();
  }, [embla, autoPlayOptions]);

  useEffect(() => {
    if (!embla) return;

    onSelect();

    onScroll();

    const snaps = embla.scrollSnapList();

    setScrollSnaps(snaps);

    embla.on('select', onSelect);

    embla.on('scroll', onScroll);
  }, [embla, setScrollSnaps, onSelect, onScroll]);

  return (
    <div className={parentClassName}>
      <div className="embla-dots">
        {scrollSnaps.map((_, index) => {
          return (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
              aria-label="Dot button"
            />
          );
        })}
      </div>
      <div className={`embla ${sliderClassName}`}>
        <div className="embla-viewport" ref={viewportRef}>
          <div className="embla-container">{slides}</div>
        </div>
      </div>
    </div>
  );
};

export default DotsCarousel;
