import type { Product } from '@hyex/dxp/types/store/Product/Product';

import { AnyProduct } from '@hyex/dxp/types/store/Product/AnyProduct';
import { findMaximumSkuPrice } from './findMaximumSkuPrice';
import { findBestBottlePrice } from './findBestBottlePrice';
import ProductBlock from '@hyex/modules/shared/Product/base';

export function makeProductBlocks(
  products: AnyProduct[],
  productWidth?: number,
  buttonClassName?: string,
  showBottlePrice = false,
  isStorePage = false
) {
  return products.map((product: AnyProduct, i) => {
    return makeProductBlock(product, productWidth, buttonClassName, showBottlePrice, isStorePage);
  });
}

export const makeProductBlock = (
  product: AnyProduct,
  width?: number,
  buttonClassName?: string,
  showBottlePrice = false,
  isStorePage = false,
  withOldPrice = true
) => {
  const result = findBestBottlePrice(product);

  return (
    <ProductBlock
      product={{ ...product, originalSkuId: product.sku }}
      bestBottlePrice={result?.bottlePrice ?? 0}
      productBlockWidth={width}
      buttonClassName={buttonClassName}
      key={product.sku}
      quantifiable={false}
      showBottlePrice={showBottlePrice}
      isStorePage={isStorePage}
      withOldPrice={withOldPrice}
    />
  );
};

export default findMaximumSkuPrice;
