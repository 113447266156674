import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/portal/app/components/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/portal/app/components/header/SuspendedHeader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/portal/app/components/home/banner/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/portal/app/components/home/bestsellers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/portal/app/components/home/learn/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/home/badge.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/facebook-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/instagram-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/pinterest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/twitter-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/product.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/FooterNavItem/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/NavBar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/libs/ui/src/FooterNav/NavList/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
