import type { Sku } from '@hyex/dxp/types/store/Product/Sku';
import { convertPrice } from '@hyex/modules/portal/domain/domain.utils';
import { parseSkuToBottlesNumber } from './parseSkuToBottlesNumber';
import { AnyProduct } from '@hyex/dxp/types/store/Product/AnyProduct';
import { DisplayedProduct } from '@hyex/modules/shared/Product/base/DisplayedProduct';

export const findBestBottlePrice = (
  product: AnyProduct | DisplayedProduct
): { sku: Sku; bottlePrice: number } | null => {
  if (!product.skus || product?.skus.length === 0) {
    //todo: digital case
    return null;
  }

  let bestPricePerBottle = Infinity;

  let bestSku: Sku | null = null;

  for (let i = 0; i < product.skus.length; i++) {
    const sku = product.skus[i];

    const numberOfBottles = parseSkuToBottlesNumber(sku);

    if (isNaN(numberOfBottles) || numberOfBottles === 0) {
      continue;
    }

    const price = convertPrice(sku?.autoship?.price ?? '$0');

    if (isNaN(price) || price === 0) {
      continue;
    }

    const pricePerBottle = price / numberOfBottles;

    if (pricePerBottle < bestPricePerBottle) {
      bestPricePerBottle = pricePerBottle;

      bestSku = sku;
    }
  }

  if (bestSku === null) {
    return null;
  }

  return { sku: bestSku, bottlePrice: bestPricePerBottle };
};
