'use client';

import React from 'react';

import type { ArticlePreview } from '@hyex/dxp/types/blog/Article';
import useIsMobile from '@hyex/utils/hooks/useIsMobile';

import SmartLink from '@hyex/modules/shared/base/Link';
import CarouselOrGrid from '@hyex/modules/shared/CarouselOrGrid';
import CategoryArticleBase from '@hyex/modules/shared/Article/views/category-article';

export interface Props {
  articles: ArticlePreview[];
  title: string;
}

const HomeLearn: React.FC<Props> = ({ articles, title }) => {
  const isMobile = useIsMobile(821);

  const articlesJsx = articles.map((article, i) => {
    return (
      <CategoryArticleBase
        article={article}
        key={article.id}
        withCategory={false}
        className={'category-article'}
        articlePath="learn/article/"
        categoryPath="learn/category/"
        prefetchLinks={false}
      />
    );
  });

  return (
    <section className="category-section">
      <div className="container">
        <div className="category-title">
          <h2 className="title">{title}</h2>
          <SmartLink
            category={{
              path: '/learn',
            }}
            isMobile={isMobile}
            entityName="Articles"
          />
        </div>
        <CarouselOrGrid
          carouselElements={() => articlesJsx}
          isMobile={isMobile}
          wrapperClassName="category-article-wrapper"
          wrapperItemClassName="category-article-list"
          carouselParentClassName="category-article-list2"
          carouselSliderClassName="articles-slider"
          maxLength={3}
        />
      </div>
    </section>
  );
};

export default HomeLearn;
