'use client';

import type { FC } from 'react';
import { useMemo } from 'react';
import Image from 'next/image';

import type { Product, ProductPreview } from '@hyex/dxp/types/store/Product/Product';
import DotsCarousel from '@hyex/modules/shared/Carousels/DotsCarousel';
import useIsMobile from '@hyex/utils/hooks/useIsMobile';

import { DEFAULT_AUTOPLAY_TIMEOUT } from '@hyex/modules/portal/constants';
import { homeBannerProducts } from '@hyex/modules/shared/Product/views/banner-products';

type PropTypes = {
  products: ProductPreview[];
};

const HomeProductsBanner: FC<PropTypes> = ({ products }) => {
  const isMobile = useIsMobile(1001);

  const bannerImg = (product: Product, index: number) => {
    return isMobile ? (
      <Image
        alt={`${product.title}`}
        src={product.images?.mobileBanner ?? ''}
        width={412}
        height={176}
        quality="70"
        // sizes="100vw"
        priority={index === 0 ? true : false}
        loading={index === 0 ? 'eager' : 'lazy'}
      />
    ) : (
      <Image
        alt={`${product.title}`}
        src={product.images?.banner ?? ''}
        width={2490}
        height={520}
        quality="70"
        // sizes="100vw"
        priority={index === 0 ? true : false}
        loading={index === 0 ? 'eager' : 'lazy'}
      />
    );
  };

  const bannerItems = homeBannerProducts(products, bannerImg);

  return (
    <section className="product-banner">
      {isMobile ? (
        <DotsCarousel
          sliderClassName={'banner-nav-wrapper'}
          parentClassName={'banner-nav'}
          slides={bannerItems()}
          options={{
            containScroll: 'keepSnaps',
            dragFree: false,
            loop: true,
          }}
        />
      ) : (
        <DotsCarousel
          sliderClassName={'banner-nav-wrapper'}
          parentClassName={'banner-nav'}
          slides={bannerItems()}
          options={{
            containScroll: 'keepSnaps',
            dragFree: false,
            loop: true,
          }}
          autoPlayOptions={{
            delay: DEFAULT_AUTOPLAY_TIMEOUT,
            stopOnInteraction: false,
            stopOnMouseEnter: true,
          }}
        />
      )}
    </section>
  );
};

export default HomeProductsBanner;
