import clsx from 'clsx';
import { StaticImageData } from 'next/image';
import type { FC } from 'react';
import Image from 'next/image';
type PropTypes = {
  enabled?: boolean;
  onClick: () => void;
  selected?: boolean;

  imgSrc?: string | StaticImageData;
  index?: number;
};

export const PrevButton: FC<PropTypes> = ({ enabled, onClick }) => {
  return (
    <button
      className="embla-button embla-button-prev link"
      onClick={onClick}
      disabled={!enabled}
      aria-label="Prev button"
    >
      <svg className="embla-button-svg" width="48" height="48" viewBox="0 0 48 48">
        <circle fill="#ecfaed" cx="24" cy="24" r="24" />
        <path
          fill="none"
          stroke="#000"
          strokeWidth="2px"
          fillRule="evenodd"
          strokeLinecap="round"
          d="M1346,2780l-7,7,7,7"
          transform="translate(-1319 -2763)"
        />
      </svg>
    </button>
  );
};

export const NextButton: FC<PropTypes> = ({ enabled, onClick }) => {
  return (
    <button
      className="embla-button embla-button-next link"
      onClick={onClick}
      disabled={!enabled}
      aria-label="Next button"
    >
      <svg className="embla-button-svg" width="48" height="48" viewBox="0 0 48 48">
        <circle fill="#ecfaed" cx="24" cy="24" r="24" />
        <path
          fill="none"
          stroke="#000"
          strokeWidth="2px"
          fillRule="evenodd"
          strokeLinecap="round"
          d="M1410,2780l7,7-7,7"
          transform="translate(-1389 -2763)"
        />
      </svg>
    </button>
  );
};

export const DotButton: FC<PropTypes> = ({ selected, onClick }) => {
  return <span className={clsx('embla-dot', { selected: selected })} aria-label="Dot button" />;
};

export const Thumb: React.FC<PropTypes> = (props) => {
  const { selected, imgSrc, onClick } = props;

  return (
    <div
      className={'carousel-thumb-slide'.concat(selected ? ' carousel-thumb-slide--selected' : '')}
    >
      <button
        onClick={onClick}
        className="carousel-thumb-slide-button"
        type="button"
        aria-label="Thumb button"
      >
        {imgSrc && (
          <Image
            className="carousel-thumb-slide-img"
            src={imgSrc}
            alt=""
            width={100}
            height={100}
          />
        )}
      </button>
    </div>
  );
};
