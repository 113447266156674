import type { Product } from '@hyex/dxp/types/store/Product/Product';
import { makeProductBlock } from '../../../../utils/products/utils';
import { AnyProduct } from '@hyex/dxp/types/store/Product/AnyProduct';

export function homeBannerProducts(
  products: AnyProduct[],
  bannerImg: (product: AnyProduct, index: number) => JSX.Element
) {
  return () =>
    products.map((product, i) => {
      return (
        <div key={i} className={'embla-slide banner-item-slide'}>
          <>
            {makeProductBlock(product, 0, 'cart-btn colored', true, true, false)}
            <div className="bg-img ">{bannerImg(product, i)}</div>
          </>
        </div>
      );
    });
}
