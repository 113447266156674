'use client';

import { type FC, useState } from 'react';
import { useRouter } from 'next/navigation';

import AddCartButton from '@hyex/modules/shared/base/AddCartButton';
import SmartLink from '@hyex/modules/shared/base/Link';
import ProductDescription from '@hyex/modules/shared/Product/common/ProductDescription';
import { NOT_PRODUCT_SKU_AVAILABLE } from '@hyex/modules/portal/constants';
import type { DisplayedProduct } from './DisplayedProduct';
import ProductImage from '@hyex/modules/shared/Product/common/ProductImage';
import ProductPrice from '@hyex/modules/shared/Product/common/ProductPrice';
import ProductQuantityPicker from '@hyex/modules/shared/Product/common/ProductQuantityPicker';
import ProductRating from '@hyex/modules/shared/Product/common/ProductRating';
import { isDefault } from '@hyex/modules/portal/domain/domain.utils';

type PropTypes = {
  product: DisplayedProduct;
  productBlockWidth?: number;
  bestBottlePrice?: number;
  buttonClassName?: string;

  quantifiable?: boolean;
  withRating?: boolean;
  routerMode?: boolean;
  showBottlePrice?: boolean;
  addDisabled?: boolean;
  isStorePage?: boolean;
  withOldPrice?: boolean;

  onDeleted?: (sku: string) => void;
};

const ProductBlock: FC<PropTypes> = ({
  product,
  bestBottlePrice,
  productBlockWidth = 0,
  buttonClassName = '',
  onDeleted,
  quantifiable = false,
  withRating = false,
  routerMode = false,
  showBottlePrice = false,
  addDisabled = false,
  isStorePage = false,
  withOldPrice = true,
}) => {
  const [amount, setAmount] = useState(1);

  const router = useRouter();

  const style = {
    block: productBlockWidth !== 0 ? { width: productBlockWidth } : {},
  };

  const onAdd = (product: DisplayedProduct) => {
    if (isDefault(product?.sku)) {
      alert(NOT_PRODUCT_SKU_AVAILABLE);

      return;
    }

    router.push(`/store/${product.sku}`);
  };

  return (
    <div className="product-block" style={style.block}>
      <div className="product-info-block">
        <h2 className="product-name">
          <SmartLink
            category={{
              path: `/store/${product.sku}`,
            }}
            isMobile={false}
            className="name"
            text={product.title}
          />
        </h2>
        <ProductDescription
          description={product.description}
          shortDescription={product?.shortDescription ?? ''}
        />

        {quantifiable && !onDeleted && <p className="product-quantity">Quantity: {amount}</p>}
        {quantifiable && <p className="product-delivered">Delivered Once</p>}
        {withRating && (
          <a href="#" className="product-rate">
            <ProductRating rating={5} />
            <span className="reviews">N Reviews</span>
          </a>
        )}

        <ProductPrice
          onSale={product?.isOnSale ?? false}
          price={product.price}
          salePrice={product.price}
          bestBottlePrice={bestBottlePrice}
          showBottlePrice={showBottlePrice}
          storePageMode={isStorePage}
          withOldPrice={withOldPrice}
        />
        {quantifiable && (
          <ProductQuantityPicker
            onlyDelete={true}
            onNewValue={setAmount}
            disabled={false}
            onDelete={() => onDeleted && onDeleted(product.sku)}
          />
        )}

        <AddCartButton
          className={buttonClassName}
          text={product?.isOutOfStock === true ? 'Sold Out' : 'Purchase'}
          onClick={() => onAdd(product)}
          disabled={addDisabled || product?.isOutOfStock === true}
        />
      </div>
      {product.images.single && (
        <ProductImage
          sku={product.sku}
          onSale={product?.isOnSale ?? false}
          image={product.images.single}
          alt={product.title}
        />
      )}
    </div>
  );
};

export default ProductBlock;
