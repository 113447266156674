import Image from 'next/image';
import Link from 'next/link';

import type { Article, ArticlePreview } from '@hyex/dxp/types/blog/Article';
import { articleFormat } from '@hyex/modules/portal/domain/domain.utils';
import { NextPage } from 'next';

type PropTypes = {
  className: string;
  article: Article | ArticlePreview;
  withCategory: boolean;
  categoryPath?: string;
  articlePath?: string;
  prefetchLinks?: boolean;
};

function isArticle(obj: any): obj is Article {
  return 'text' in obj;
}

const CategoryArticleBase: NextPage<PropTypes> = ({
  className,
  article,
  withCategory,
  categoryPath,
  articlePath,
  prefetchLinks = true,
}) => {
  const { slug, image, category, title } = article;

  const isFullArticle = isArticle(article);

  const trimmedText = isFullArticle
    ? articleFormat(article.text)
    : article?.shortDescription || article?.meta?.description;

  const categoryFullPath = `${categoryPath}${category?.value}`;

  const articleFullPath = `${articlePath}${slug}`;

  return (
    <div className={className}>
      {image ? (
        <div className="category-article-img">
          <Link href={articleFullPath} prefetch={prefetchLinks}>
            <Image
              alt={`${article.title} about ${isFullArticle && article?.product?.title}`}
              src={image}
              width={380}
              height={380}
              quality="70"
              priority
            />
          </Link>
        </div>
      ) : (
        <div className="category-article-img">
          {articlePath}
          <Link href={articleFullPath} prefetch={prefetchLinks}></Link>
        </div>
      )}
      <div className="category-article-text">
        {withCategory && category && (
          <h4>
            <Link href={categoryFullPath} prefetch={prefetchLinks}>
              {category.name}
            </Link>
          </h4>
        )}

        <h3 className="text-overflow">
          <Link href={articleFullPath} prefetch={prefetchLinks}>
            {title}
          </Link>
        </h3>
        <p className="text-overflow">{trimmedText}</p>
      </div>
    </div>
  );
};

export default CategoryArticleBase;
