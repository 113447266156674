import type { FC } from 'react';
import React from 'react';

type PropTypes = {
  rating: number;
};

const ProductRating: FC<PropTypes> = ({ rating }) => {
  const fullStars = Math.floor(rating);

  const hasHalfStar = rating % 1 !== 0;

  const createStars = () => {
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<use key={`star-${i}`} href="#stars-full-star" xlinkHref="#stars-full-star" />);
    }

    if (hasHalfStar) {
      stars.push(<use key="half-star" href="#stars-half-star" xlinkHref="#stars-half-star" />);
    }

    for (let i = stars.length; i < 5; i++) {
      stars.push(
        <use key={`empty-star-${i}`} href="#stars-empty-star" xlinkHref="#stars-empty-star" />
      );
    }

    return stars;
  };

  return (
    <div className="stars">
      <svg id="stars">
        <symbol id="stars-empty-star" viewBox="0 0 102 18" fill="#cfe7bd">
          <path d="M9.5,14.25l-5.584,2.936,1.066-6.218L.465,6.564l6.243-.907L9.5,0l2.792,5.657,6.243.907-4.517,4.404,1.066 6.218z" />
        </symbol>
        <symbol id="stars-full-star" viewBox="0 0 102 18" fill="#5aac1c">
          <path d="M9.5,14.25l-5.584,2.936,1.066-6.218L.465,6.564l6.243-.907L9.5,0l2.792,5.657,6.243.907-4.517,4.404,1.066 6.218z" />
        </symbol>
        <symbol id="stars-half-star" viewBox="0 0 102 18" fill="#5aac1c">
          <use href="#stars-empty-star" xlinkHref="#stars-empty-star" />
          <path d="M9.5,14.2l-5.6,2.9L5,11L0.5,6.6l6.2-0.9L9.5,0V14.2z" />
        </symbol>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        className="rating"
      >
        {createStars()}
      </svg>
    </div>
  );
};

export default ProductRating;
