'use client';

import type { ReactNode } from 'react';
import React from 'react';

import DotsCarousel from '../Carousels/DotsCarousel';

interface Props {
  carouselElements: () => ReactNode[];
  gridElement?: () => ReactNode;

  isMobile: boolean;
  wrapperClassName?: string;
  wrapperItemClassName?: string;

  carouselParentClassName?: string;
  carouselSliderClassName?: string;

  maxLength?: number;
}

//todo: dots only(
const CarouselOrGrid: React.FC<Props> = ({
  carouselElements,
  isMobile,
  wrapperClassName,
  wrapperItemClassName,
  carouselParentClassName,
  carouselSliderClassName,
  maxLength = 3,
  gridElement,
}) => {
  const nodes = carouselElements();

  const nodesLength = nodes.length;

  const hasReachedMaximum = nodesLength > maxLength;

  const useSlides = isMobile || hasReachedMaximum;

  const slides = () => {
    return nodes.map((slide, i) => {
      return (
        <div
          key={i}
          className={`${useSlides ? 'embla-slide' : ''} ${
            !isMobile && hasReachedMaximum ? 'embla-slide-m' : 'embla-slide-l'
          }`}
        >
          {slide}
        </div>
      );
    });
  };

  return (
    <>
      {useSlides ? (
        <>
          <DotsCarousel
            slides={slides()}
            parentClassName={carouselParentClassName}
            sliderClassName={carouselSliderClassName}
            options={{ containScroll: 'trimSnaps', dragFree: true, loop: false }}
          />
        </>
      ) : (
        <>
          <div className={wrapperClassName}>
            <div className={wrapperItemClassName}>{gridElement ? gridElement() : nodes}</div>
          </div>
        </>
      )}
    </>
  );
};

export default CarouselOrGrid;
