'use client';

import clsx from 'clsx';
import Link from 'next/link';
import type { FC } from 'react';

type PropType = {
  className?: string;
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  redirectTo?: string;
};

const AddCartButton: FC<PropType> = ({
  text,
  disabled = false,
  className,
  onClick,
  redirectTo,
}) => {
  const handleAddToCartButton = () => {
    onClick && onClick();
  };

  const disabledClass = clsx(className, { 'disabled-item': disabled });

  return redirectTo ? (
    <>
      <Link href={redirectTo} className={disabled ? disabledClass : className}>
        <button
          className={disabled ? disabledClass : className}
          onClick={handleAddToCartButton}
          disabled={disabled}
          aria-label="Add item to cart"
        >
          {text}
        </button>
      </Link>
    </>
  ) : (
    <button
      className={disabled ? disabledClass : className}
      onClick={handleAddToCartButton}
      disabled={disabled}
      aria-label="Add item to cart"
    >
      {text}
    </button>
  );
};

export default AddCartButton;
