'use client';

import type { FC } from 'react';

import type { Product } from '@hyex/dxp/types/store/Product/Product';
import CarouselOrGrid from '@hyex/modules/shared/CarouselOrGrid';
import useIsMobile from '@hyex/utils/hooks/useIsMobile';

import SmartLink from '@hyex/modules/shared/base/Link';
import { topPicksProducts } from '@hyex/modules/shared/Product/views/top-picks-products';

type PropTypes = {
  products: Product[];
  title: string;
};

const HomeTopPicks: FC<PropTypes> = ({ products, title }) => {
  const isMobile = useIsMobile(821);

  const elements = () => topPicksProducts(products);

  return (
    <section className="bestsellers">
      <div className="container">
        <div className="category-title">
          <h2 className="title">{title}</h2>
          <SmartLink
            className="title-link"
            category={{
              path: '/store',
            }}
            isMobile={isMobile}
            entityName="Products"
          />
        </div>

        <CarouselOrGrid
          carouselElements={elements}
          wrapperClassName="container"
          wrapperItemClassName="products-store-list"
          isMobile={isMobile}
          carouselSliderClassName="products-slider"
          carouselParentClassName="category-products-list"
          maxLength={3}
        />
      </div>
    </section>
  );
};

export default HomeTopPicks;
